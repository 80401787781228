import Dialog from 'components/dist/atoms/Dialog';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { useRouter } from 'next/router';
import { FC, useContext, useState } from 'react';
import { RoleLenderGroupLevel } from 'src/backend';
import { Route } from 'src/constants/ui';
import { AuthContext } from 'src/contexts/auth-context';
import { useUser } from 'src/hooks/use-user';
import { useGetListPossibleRolesQuery, useSetLoggedRoleMutation } from 'src/services/userApi';
import { intervalManager } from 'src/utils';
import { getInitials } from 'src/utils/get-initials';
import { toast } from 'src/utils/toast';
import { getRoleLevelLabel } from 'src/utils/user/get-role-level-label';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { NextLinkComposed } from '../common/next-link-composed';
import { ContactSupport } from '../support/contact-support';
import { UserAvatar } from '../user/user-avatar';


export const AccountPopover = () => {
  const { user, userRole, isBorrower } = useUser();
  const { data = [] } = useGetListPossibleRolesQuery();
  const [isContactSupportOpen, setIsContactSupportOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const [setLoggedRole] = useSetLoggedRoleMutation()
  const router = useRouter();

  const onRoleSelected = async (roleLevel: RoleLenderGroupLevel) => {
    await setLoggedRole({ roleLevel }).unwrap()
    router.replace(Route.HOME);
  }
  const handleToggleSupportChat = (open: boolean): void => {
    setIsContactSupportOpen(open);
  }

  const handleLogout = async () => {
    try {
      intervalManager.clearAll();
      await logout();
    } catch (err) {
      toast({
        content: 'Unable to logout'
      });
    }
  };

  const handleOpenSupport = (): void => {
    setIsContactSupportOpen(true);
  }
  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenu.Trigger asChild>
          <Stack row space="sm" items="center"
            className='cursor-pointer'
            data-testid='account-popover-trigger'
            id={'account-button' /* for selenium automation */}
          >
            <UserAvatar
              avatarDocId={user.avatarDocId}
              userId={user.id}
              role={userRole}
              size="sm"
            >
              {getInitials(getUserDisplayName(user))}
            </UserAvatar>
            <Icon name="NavArrowDown" width={20} height={20} strokeWidth={2} className='text-black-primary' />
          </Stack>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className='gap-1 max-w-80 w-72 gap-2'
          align="end"
          side="bottom"
          alignOffset={0}>
          <DropdownMenu.Item className='pointer-events-none'>
            <Stack row space='sm' items="center">
              <UserAvatar
                avatarDocId={user.avatarDocId}
                userId={user.id}
                role={userRole}
              >
                {getInitials(getUserDisplayName(user))}
              </UserAvatar>
              <div>
                <Text size="sm">
                  {getUserDisplayName(user)}
                </Text>
              </div>
            </Stack>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item className="h-9" asChild>
            <NextLinkComposed to={{ pathname: '/user/profile' }}>
              <Stack row space='sm'>
                <Icon name="Settings" width={20} height={20} strokeWidth={2} className='text-black-primary' />
                <Text size="sm">
                  Settings
                </Text>
              </Stack>
            </NextLinkComposed>
          </DropdownMenu.Item>
          <DropdownMenu.Item className="h-9" onSelect={handleOpenSupport}>
            <Stack row space='sm'>
              <Icon name="ChatLines" width={20} height={20} strokeWidth={2} className='text-black-primary' />
              <Stack>
                <Text size="sm">
                  Contact support
                </Text>
                <Text variant="secondary" italic size="xs">9am - 5pm MDT (Mon-Fri)</Text>
              </Stack>
            </Stack>
          </DropdownMenu.Item>
          <DropdownMenu.Item className="h-9" asChild>
            <NextLinkComposed to={{ pathname: '/v2/learning-center' }}>
              <Stack row space='sm'>
                <Icon name="InfoEmpty" width={20} height={20} strokeWidth={2} className='text-black-primary' />
                <Text size="sm">
                  Learning center
                </Text>
              </Stack>
            </NextLinkComposed>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          {isBorrower && <DropdownMenu.Item className="h-9" asChild>
            <NextLinkComposed to={{ pathname: '/portal' }}>
              <Stack row space='sm'>
                <Icon name="ViewStructureDown" width={20} height={20} strokeWidth={2} className='text-black-primary' />
                <Text size="sm">
                  Go to my portal
                </Text>
              </Stack>
            </NextLinkComposed>
          </DropdownMenu.Item>}
          {data.length > 1 && <>
            <DropdownMenu.Label className='text-xs'>
              Switch Loan Role
            </DropdownMenu.Label>
            {data
              .filter(selectRole => selectRole.roleLenderGroupLevel !== user.loggedRoleGroup)
              .map(selectRole => <DropdownMenu.Item
                onSelect={() => onRoleSelected(selectRole.roleLenderGroupLevel)}
                key={selectRole.roleLenderGroupLevel}
                className="h-9" asChild>
                <Stack row space='sm'>
                  <Icon name="User" width={20} height={20} strokeWidth={2} className='text-black-primary' />
                  <Text size="sm">
                    {getRoleLevelLabel(selectRole.roleLenderGroupLevel)}
                  </Text>
                </Stack>
              </DropdownMenu.Item>)}
          </>}
          <DropdownMenu.Item className="h-9" onSelect={handleLogout}>
            <Stack row space='sm'>
              <Icon name="LogOut" width={20} height={20} strokeWidth={2} className='text-destructive' />
              <Text size="sm" variant="destructive">
                Log out
              </Text>
            </Stack>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
      <Dialog
        open={isContactSupportOpen}
        onOpenChange={handleToggleSupportChat}>
        <Dialog.Content className='max-w-[95%]'>
          <ContactSupport />
        </Dialog.Content>
      </Dialog>
    </>
  );
};
